import React from "react";
import { Link } from "react-router-dom";

import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import Switcher from "../../components/switcher";

import { restrictions } from "../../data/data"; 

export default function Privacy() {
    return (
        <>
            <Navbar navclass="defaultscroll is-sticky" navlight={false} manuclass="justify-end" />

            <section className="relative table w-full py-32 lg:py-40 bg-gray-50 dark:bg-slate-800">
                <div className="container relative">
                    <div className="grid grid-cols-1 text-center mt-10">
                        <h3 className="text-3xl leading-normal font-semibold">Privacy Policy</h3>
                    </div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="tracking-[0.5px] mb-0 inline-block">
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-red-500"><Link to="/">Sky Global Education</Link></li>
                        <li className="inline-block text-base text-slate-950 dark:text-white mx-0.5 ltr:rotate-0 rtl:rotate-180"><i className="mdi mdi-chevron-right"></i></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-red-500"><Link to="/privacy">Privacy</Link></li>
                    </ul>
                </div>
            </section>

            <section className="relative md:py-24 py-16">
                <div className="container relative">
                    <div className="md:flex justify-center">
                        <div className="md:w-3/4">
                            <div className="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md">
                                <h5 className="text-xl font-semibold mb-4">Overview:</h5>
                                <p className="text-slate-400">At Sky Global Education, we are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and safeguard your personal information when you use our website and services.</p>
                                <p className="text-slate-400">We may update this policy from time to time to reflect changes in our practices or legal requirements. We encourage you to review this policy periodically to stay informed about how we are protecting your information.</p>

                                <h5 className="text-xl font-semibold mb-4 mt-8">How We Use Your Information:</h5>
                                <ul className="list-none text-slate-400 mt-4">
                                    {restrictions.map((item, index) => (
                                        <li className="flex mt-2" key={index}><i className="mdi mdi-chevron-right text-red-500 text-lg align-middle me-2"></i>{item}</li>
                                    ))}
                                </ul>

                                <h5 className="text-xl font-semibold mb-4 mt-8">Information Provided Voluntarily:</h5>
                                <p className="text-slate-400">When you apply for scholarships or request information, we collect personal data such as your name, email address, and educational background. This information is used to process your application and provide you with relevant updates and support.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            <Switcher />
        </>
    );
}
