import React, { useEffect } from 'react';

const IframeEnquiryForm = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://widgets.in8.nopaperforms.com/emwgts.js';
    document.body.appendChild(script);
    var npf_d='https://skyglobaleducation.in8.nopaperforms.com';
                    var npf_c='6185';
                    var npf_m='1';
                    var s=document.createElement("script");
                    s.type="text/javascript";
                    s.async=true;
                    s.src="https://track.nopaperforms.com/js/track.js";
                    document.body.appendChild(s);
  }, []);

  return (
    <div className="npf_wgts" data-height="660px" data-w="b6cfb82dc8e07c08c5c9fca69b6d6228"></div>
    
  );
 
                    
      
};

export default IframeEnquiryForm;
