import React, { useState } from "react";
import { Link } from "react-router-dom";

import Navbar from "../../components/navbar";
import Switcher from "../../components/switcher";
import Footer from "../../components/footer";

import { faqData, restrictions } from "../../data/data";

import { FiChevronUp } from '../../assets/icons/vander';

export default function Terms() {
    const [activeTab, setActiveTab] = useState(1);

    return (
        <>
            <Navbar navclass="defaultscroll is-sticky" navlight={false} manuclass="justify-end" />

            <section className="relative table w-full py-32 lg:py-40 bg-gray-50 dark:bg-slate-800">
                <div className="container relative">
                    <div className="grid grid-cols-1 text-center mt-10">
                        <h3 className="text-3xl leading-normal font-semibold">Terms and Conditions</h3>
                    </div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="tracking-[0.5px] mb-0 inline-block">
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-red-500"><Link to="/">Sky Global Education</Link></li>
                        <li className="inline-block text-base text-slate-950 dark:text-white mx-0.5 ltr:rotate-0 rtl:rotate-180"><i className="mdi mdi-chevron-right"></i></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-red-500"><Link to="/terms">Terms</Link></li>
                    </ul>
                </div>
            </section>

            <section className="relative md:py-24 py-16">
                <div className="container relative">
                    <div className="md:flex justify-center">
                        <div className="md:w-3/4">
                            <div className="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md">
                                <h5 className="text-xl font-semibold mb-4">Introduction:</h5>
                                <p className="text-slate-400">These Terms and Conditions govern your use of the Sky Global Education website and services. Please read them carefully to understand your rights and obligations.</p>

                                <h5 className="text-xl font-semibold mb-4 mt-8">User Agreements:</h5>
                                <p className="text-slate-400">By using our services, you agree to comply with the terms outlined here. These terms include eligibility criteria for scholarships, application processes, and restrictions on the use of our platform.</p>

                                <h5 className="text-xl font-semibold mb-4 mt-8">Restrictions:</h5>
                                <p className="text-slate-400">You are specifically restricted from all of the following:</p>
                                <ul className="list-none text-slate-400 mt-3">
                                    {restrictions.map((item, index) => (
                                        <li className="flex mt-2" key={index}><i className="mdi mdi-chevron-right text-red-500 text-lg align-middle me-2"></i>{item}</li>
                                    ))}
                                </ul>

                                <h5 className="text-xl font-semibold mt-8">FAQ:</h5>

                                <div className="mt-6">
                                    {faqData.map((item, index) => (
                                        <div className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4" key={index}>
                                            <h2 className="text-base font-semibold">
                                                <button
                                                    type="button"
                                                    className={`flex justify-between items-center p-5 w-full font-medium text-start ${activeTab === item.id ? 'bg-gray-50 dark:bg-slate-800 text-red-500' : ''}`}
                                                    onClick={() => setActiveTab(item.id)}
                                                >
                                                    <span>{item.title}</span>
                                                    <FiChevronUp className={`size-4 shrink-0 ${activeTab === item.id ? '' : 'rotate-180'}`}></FiChevronUp>
                                                </button>
                                            </h2>
                                            <div className={activeTab === item.id ? '' : 'hidden'}>
                                                <div className="p-5">
                                                    <p className="text-slate-400 dark:text-gray-400">{item.desc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Switcher />
            <Footer />
        </>
    );
}
