// eslint-disable-next-line
import React, { useState } from "react";
import { Link } from "react-router-dom";

import travel from "../assets/images/travel-train-station.PNG";

import Navbar from "../components/navbar";
import Footer from "../components/footer";
import Switcher from "../components/switcher";

import { FiPhone, FiMail, FiMapPin, FiX } from "../assets/icons/vander";

export default function Contact() {
  let [modal, setModal] = useState(false);
  return (
    <>
      <Navbar
        navclass="defaultscroll is-sticky"
        navlight={false}
        manuclass="justify-end"
      />
      <section className="relative table w-full items-center py-36 bg-[url('../../assets/images/bg/cta.jpg')] bg-top bg-no-repeat bg-cover">
        <div className="absolute inset-0 bg-gradient-to-b from-slate-900/60 via-slate-900/80 to-slate-900"></div>
        <div className="container relative">
          <div className="grid grid-cols-1 pb-8 text-center mt-10">
            <h3 className="text-4xl leading-normal tracking-wider font-semibold text-white">
              Contact Us
            </h3>
          </div>
        </div>

        <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
          <ul className="tracking-[0.5px] mb-0 inline-block">
            <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white">
              <Link to="/">Sky Global Education</Link>
            </li>
            <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180">
              <i className="mdi mdi-chevron-right"></i>
            </li>
            <li
              className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white"
              aria-current="page"
            >
              Contact
            </li>
          </ul>
        </div>
      </section>
      <section className="relative lg:py-24 py-16">
        <div className="container">
          <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-6">
            <div className="lg:col-span-7 md:col-span-6">
              <img
                src={travel}
                className="w-full max-w-[500px] mx-auto"
                alt="Sky Global Education"
              />
            </div>

            <div className="lg:col-span-5 md:col-span-6">
              <div className="lg:ms-5">
                <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-800 p-6">
                  <h3 className="mb-6 text-2xl leading-normal font-semibold">
                    Get in touch!
                  </h3>
                  <p className="text-slate-400">
                    Reach out to us for any inquiries or support. We are here to
                    help you achieve your academic dreams abroad.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container lg:mt-24 mt-16">
          <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-6">
            <div className="text-center px-6">
              <div className="relative text-transparent">
                <div className="size-20 bg-red-500/5 text-red-500 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                  <FiPhone></FiPhone>
                </div>
              </div>

              <div className="content mt-7">
                <h5 className="h5 text-lg font-semibold">Phone</h5>
                <p className="text-slate-400 mt-3">
                  Call us for any inquiries or assistance regarding your
                  application process.
                </p>

                <div className="mt-5">
                  <Link
                    to="tel:+263 78 288 4986"
                    className="text-red-500 font-medium"
                  >
                    +263 78 288 4986
                  </Link>
                </div>
              </div>
            </div>

            <div className="text-center px-6">
              <div className="relative text-transparent">
                <div className="size-20 bg-red-500/5 text-red-500 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                  <FiMail></FiMail>
                </div>
              </div>

              <div className="content mt-7">
                <h5 className="h5 text-lg font-semibold">Email</h5>
                <p className="text-slate-400 mt-3">
                  Send us an email for detailed queries or to request more
                  information.
                </p>

                <div className="mt-5">
                  <Link
                    to="mailto:reservations@skyglobaleducation.comm"
                    className="text-red-500 font-medium"
                  >
                    reservations@skyglobaleducation.com
                  </Link>
                </div>
              </div>
            </div>

            <div className="text-center px-6">
              <div className="relative text-transparent">
                <div className="size-20 bg-red-500/5 text-red-500 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                  <FiMapPin></FiMapPin>
                </div>
              </div>

              <div className="content mt-7">
                <h5 className="h5 text-lg font-semibold">Location</h5>
                <p className="text-slate-400 mt-3">
                  123 Academic Lane, Suite 100, <br /> Harare, Zimbabwe
                </p>

                <div className="mt-5">
                  <Link
                    to="#"
                    onClick={() => setModal(!modal)}
                    className="video-play-icon read-more lightbox text-red-500 font-medium"
                  >
                    View on Google map
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Switcher />
      {modal && (
        <div className="w-full h-screen bg-slate-900/80 fixed top-0 left-0 bottom-0 right-0 z-999 flex items-center justify-center">
          <div className="w-full h-full px-5 md:px-40 md-py-20 py-5">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d121570.11890594428!2d30.99913029721052!3d-17.78858486292669!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1931b10cd6171c23%3A0x4ce05a20c23299ed!2sGlobal%20Education%20Zimbabwe!5e0!3m2!1sen!2sza!4v1726740129954!5m2!1sen!2sza"
              width="100%"
              height="100%"
              title="map"
              loading="lazy"
            ></iframe>
            
          </div>
          <button
            className="text-slate-400 absolute top-[20px] right-[20px]"
            onClick={() => setModal(!modal)}
          >
            <FiX className="size-5"></FiX>
          </button>
        </div>
      )}
    </>
  );
}
