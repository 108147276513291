import React from "react";
import { Link } from "react-router-dom";

import Navbar from "../../components/navbar";
import HelpcenterAbout from "../../components/helpcenter-about";
import Footer from "../../components/footer";
import Switcher from "../../components/switcher";

import { FiUser, FiMail, FiBook, FiMessageCircle } from '../../assets/icons/vander'
import Form from "../../components/form";

export default function HelpcenterSupport() {
    return (
        <>
        <Navbar navclass="defaultscroll is-sticky" navlight={true} manuclass="justify-end nav-light"/>

        <section className="relative table w-full py-36 bg-[url('../../assets/images/bg/cta.jpg')] bg-center bg-no-repeat bg-cover">
            <div className="absolute inset-0 bg-black opacity-80"></div>
            <div className="container relative">
                <div className="grid grid-cols-1 pb-8 text-center mt-10">
                    <h3 className="text-4xl leading-normal tracking-wider font-semibold text-white">Submit Your Support Request</h3>
                </div>
            </div>
            
            <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                <ul className="tracking-[0.5px] mb-0 inline-block">
                   <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">Sky Global Education</Link></li>
                   <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><i className="mdi mdi-chevron-right"></i></li>
                   <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/helpcenter">Help Center</Link></li>
                    <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><i className="mdi mdi-chevron-right"></i></li>
                    <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white" aria-current="page">Support</li>
                </ul>
            </div>
        </section>
        <Form />

        <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800"> 
            <HelpcenterAbout/>
        </section>

        <Footer/>
        <Switcher/>

        </>
    )
}
