import React from 'react';
import img from ".././assets/images/travel-train-station.PNG"
import IframeEnquiryForm from './EnquiryForm';

export default function Form(){
    
    return(
            <>
            <section className="relative table w-full h-screen items-center">
        <div className="container h-full flex flex-col lg:flex-row mt-10 pt-10 pb-10">
          <div className="flex-grow  md:mr-4">
            <IframeEnquiryForm className="h-full" />
          </div>
          <div className="hidden lg:block lg:w-1/2 h-full pb-40">
            <img
              src={img}
              alt="Your Description"
              className="w-full h-full object-cover rounded-lg"
            />
          </div>
        </div>
      </section>
            </>
    )
}