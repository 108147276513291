import React from "react";
import { Link } from "react-router-dom";

import Navbar from "../../components/navbar";
import GetInTouch from "../../components/get-in-touch";
import HelpcenterAbout from "../../components/helpcenter-about";
import Footer from "../../components/footer";
import Switcher from "../../components/switcher";

import { FiHelpCircle } from "../../assets/icons/vander";

export default function Helpcenter() {
    return (
        <>
        <Navbar navclass="defaultscroll is-sticky" navlight={true} manuclass="justify-end nav-light"/>
        <section className="relative table w-full py-36 bg-[url('../../assets/images/bg/cta.jpg')] bg-center bg-no-repeat bg-cover">
            <div className="absolute inset-0 bg-black opacity-80"></div>
            <div className="container relative">
                <div className="grid grid-cols-1 pb-8 text-center mt-10">
                    <h3 className="mb-6 text-4xl leading-normal tracking-wider font-semibold text-white">Hello! <br/> How can we assist you?</h3>
                </div>
            </div>
            
            <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                <ul className="tracking-[0.5px] mb-0 inline-block">
                    <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">Sky Global Education</Link></li>
                    <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><i className="mdi mdi-chevron-right"></i></li>
                    <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white" aria-current="page">Help Center</li>
                </ul>
            </div>
        </section>

        <section className="relative md:py-24 py-16">
            <HelpcenterAbout/>

            <div className="container relative md:mt-24 mt-16">
                <div className="grid grid-cols-1 pb-8 text-center">
                    <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Get Started with Your Scholarship Journey</h3>

                    <p className="text-slate-400 max-w-xl mx-auto">Looking to apply for educational scholarships? We provide detailed guidance to help you navigate the application process and secure funding for your studies!</p>
                </div>

                <div className="grid md:grid-cols-2 grid-cols-1 mt-8 gap-6">
                    <div className="flex">
                        <FiHelpCircle className="fea icon-ex-md text-blue-500 me-3 mt-1 size-6"></FiHelpCircle>
                        <div className="flex-1">
                            <h5 className="mb-2 text-xl font-semibold">How does <span className="text-blue-500">Sky Global Education</span> support you?</h5>
                            <p className="text-slate-400">We provide comprehensive resources and personalized advice to help you find and apply for the best scholarships available.</p>
                        </div>
                    </div>
                    
                    <div className="flex">
                        <FiHelpCircle className="fea icon-ex-md text-blue-500 me-3 mt-1 size-6"></FiHelpCircle>
                        <div className="flex-1">
                            <h5 className="mb-2 text-xl font-semibold">What is the process for applying for scholarships?</h5>
                            <p className="text-slate-400">We guide you through each step of the application process, from finding suitable scholarships to submitting your application.</p>
                        </div>
                    </div>
                    
                    <div className="flex">
                        <FiHelpCircle className="fea icon-ex-md text-blue-500 me-3 mt-1 size-6"></FiHelpCircle>
                        <div className="flex-1">
                            <h5 className="mb-2 text-xl font-semibold">How can you maximize your chances of securing a scholarship?</h5>
                            <p className="text-slate-400">Our experts provide tips and strategies to enhance your application and increase your chances of receiving funding.</p>
                        </div>
                    </div>
                    
                    <div className="flex">
                        <FiHelpCircle className="fea icon-ex-md text-blue-500 me-3 mt-1 size-6"></FiHelpCircle>
                        <div className="flex-1">
                            <h5 className="mb-2 text-xl font-semibold">Is <span className="text-blue-500">Sky Global Education</span> secure and trustworthy?</h5>
                            <p className="text-slate-400">Yes, we are committed to providing reliable and secure assistance to help you achieve your educational goals with confidence.</p>
                        </div>
                    </div>
                </div>
            </div>

            <GetInTouch/>
        </section>
        <Footer/>
        <Switcher/>
        </>
    )
}
