import React from 'react'

//Icons
import SchoolIcon from '@mui/icons-material/School';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DomainIcon from '@mui/icons-material/Domain';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'; 

const WhyUs = () => {
    return (
        <div className=' mt-24 mb-36 p-6 mx-auto max-w-sm sm:max-w-xl md:max-w-full lg:max-w-screen-xl space-y-40'>
            <div className="flex flex-wrap -mx-4">
                <div className="w-full px-4">
                    <div className="mx-auto mb-4 max-w-[510px] text-center lg:mb-4">
                        <h2 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">
                            Why Choose Skyglobal Education?
                        </h2>
                        <p className=" subdesc">
                            9 Key Reasons to Partner with Us
                        </p>
                    </div>
                </div>
            </div>
            <div className="grid row-gap-8 sm:row-gap-0 sm:grid-cols-2 lg:grid-cols-3">
                <div className="p-8 border-b sm:border-r">
                    <div className="max-w-md text-center">
                        <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16 shadow-md">
                            <DoneOutlineIcon className='w-8 h-8 text-slate-400' />
                        </div>
                        <h6 className="mb-2 font-semibold leading-5">Expertise</h6>
                        <p className="mb-3 text-sm text-slate-400 leading-5">
                            Our team of seasoned professionals offers unparalleled expertise in education systems and admissions across multiple countries.
                        </p>
                    </div>
                </div>
                <div className="p-8 border-b lg:border-r">
                    <div className="max-w-md text-center">
                        <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16 shadow-md">
                            <AssignmentTurnedInIcon className='w-10 h-10 text-slate-400' />
                        </div>
                        <h6 className="mb-2 font-semibold leading-5">Customized Solutions</h6>
                        <p className="mb-3 text-sm text-slate-400 leading-5">
                            We recognize each student’s unique journey and offer tailored solutions to meet their specific needs and goals.
                        </p>
                    </div>
                </div>
                <div className="p-8 border-b sm:border-r lg:border-r-0">
                    <div className="max-w-md text-center">
                        <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16 shadow-md">
                            <CheckCircleOutlineIcon className='w-10 h-10 text-slate-400' />
                        </div>
                        <h6 className="mb-2 font-semibold leading-5">Transparency</h6>
                        <p className="mb-3 text-sm text-slate-400 leading-5">
                            We ensure complete transparency throughout the entire process, keeping students informed at every step of their application journey.
                        </p>
                    </div>
                </div>
                <div className="p-8 border-b lg:border-r">
                    <div className="max-w-md text-center">
                        <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16 shadow-md">
                            <AttachMoneyIcon className='w-10 h-10 text-slate-400' />
                        </div>
                        <h6 className="mb-2 font-semibold leading-5">Affordable Services</h6>
                        <p className="mb-3 text-sm text-slate-400 leading-5">
                            We believe in making quality education accessible by offering competitive and affordable service packages.
                        </p>
                    </div>
                </div>
                <div className="p-8 border-b sm:border-r">
                    <div className="max-w-md text-center">
                        <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16 shadow-md">
                            <DomainIcon className='w-10 h-10 text-slate-400' />
                        </div>
                        <h6 className="mb-2 font-semibold leading-5">University Selection</h6>
                        <p className="mb-3 text-sm text-slate-400 leading-5">
                            We assist in selecting the best courses, colleges, or universities and countries that fit your academic profile, interests, and budget.
                        </p>
                    </div>
                </div>
                <div className="p-8 border-b">
                    <div className="max-w-md text-center">
                        <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16 shadow-md">
                            <VolunteerActivismIcon className='w-10 h-10 text-slate-400' />
                        </div>
                        <h6 className="mb-2 font-semibold leading-5">Application Guidance</h6>
                        <p className="mb-3 text-sm text-slate-400 leading-5">
                            From filling out forms to crafting compelling SOPs, essays, and recommendation letters, we guide you through every step of the application process.
                        </p>
                    </div>
                </div>
                <div className="p-8 border-b sm:border-r lg:border-b-0">
                    <div className="max-w-md text-center">
                        <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16 shadow-md">
                            <AssignmentOutlinedIcon className='w-10 h-10 text-slate-400' />
                        </div>
                        <h6 className="mb-2 font-semibold leading-5">Visa Assistance</h6>
                        <p className="mb-3 text-sm text-slate-400 leading-5">
                            We offer comprehensive support through the visa process, including document preparation, visa workshops, and interview coaching.
                        </p>
                    </div>
                </div>
                <div className="p-8 border-b lg:border-b-0 lg:border-r">
                    <div className="max-w-md text-center">
                        <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16 shadow-md">
                            <RecordVoiceOverIcon className='w-10 h-10 text-slate-400' />
                        </div>
                        <h6 className="mb-2 font-semibold leading-5">Pre-Departure Briefing</h6>
                        <p className="mb-3 text-sm text-slate-400 leading-5">
                            We provide thorough briefings to prepare students for their new environment, covering accommodation, travel, and cultural orientation.
                        </p>
                    </div>
                </div>
                <div className="p-8 sm:border-r lg:border-r-0">
                    <div className="max-w-md text-center">
                        <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16 shadow-md">
                            <SchoolIcon className='w-10 h-10 text-slate-400' />
                        </div>
                        <h6 className="mb-2 font-semibold leading-5">Scholarship Assistance</h6>
                        <p className="mb-3 text-sm text-slate-400 leading-5">
                            We guide you in finding and applying for scholarships to help reduce your educational expenses.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhyUs
