import React, { useEffect } from 'react';

const DynamicTrackingCode = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = `
      var npf_d='https://skyglobaleducation.in8.nopaperforms.com';
      var npf_c='6185';
      var npf_m='1';
      var s=document.createElement("script");
      s.type='text/javascript';
      s.async=true;
      s.src='https://track.nopaperforms.com/js/track.js';
      document.body.appendChild(s);
    `;
    document.body.appendChild(script);
  }, []);

  return null;
};

export default DynamicTrackingCode;
