import React from 'react'
import imga from "../assets/images/about/a.jpg"
import imgb from "../assets/images/about/b.jpg"
import imgc from "../assets/images/about/c.jpg"
import imgd from "../assets/images/about/d.jpg"

const AboutDescription = () => {
  return (
    <>
      <section className="py-40">
        <div className="flex flex-col px-8 mx-auto space-y-12 max-w-7xl xl:px-12">
          <div className="relative">
            <h2 className="w-full text-3xl font-bold text-center sm:text-4xl">About Sky Global Education</h2>
            <p className="w-full py-8 mx-auto -mt-2 text-base text-center text-slate-400 font-medium sm:max-w-3xl">
              At Sky Global Education, we are dedicated to transforming the educational landscape by offering exceptional opportunities for students around the globe. Our mission is to bridge the gap between students and their academic goals by providing access to a wide range of resources, support, and financial aid. We strive to create a world where every student has the chance to pursue their dreams and reach their full potential.
            </p>
          </div>
          <div className="flex flex-col mb-10 animated fadeIn sm:flex-row">
            <div className="flex items-center mb-16 sm:w-1/2 md:w-5/12 sm:order-last">
              <img className="rounded-lg shadow-xl" src={imga} alt="Commitments" />
            </div>
            <div className="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pr-16">
              <h3 className="mt-2 text-xl font-semibold sm:text-left sm:text-2xl">Our Commitments</h3>
              <p className="mt-5 text-base text-slate-400 sm:text-left lg:text-lg">
                At Sky Global Education, we are unwavering in our commitment to making education affordable and accessible to students worldwide. We understand that pursuing higher education can be a challenging and costly endeavor, and we are here to alleviate those challenges. Our dedication lies in offering a range of affordable study programs, comprehensive scholarship opportunities, and personalized guidance to ensure that every student can achieve their academic and career aspirations without financial barriers. We are committed to empowering students to unlock their full potential and pursue their dreams with confidence.
              </p>
            </div>
          </div>
          <div className="flex flex-col mb-10 animated fadeIn sm:flex-row">
            <div className="flex items-center mb-16 sm:w-1/2 md:w-5/12">
              <img className="rounded-lg shadow-xl" src={imgb} alt="Objective" />
            </div>
            <div className="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pl-16">
              <h3 className="mt-2 text-xl font-semibold sm:text-left sm:text-2xl">Our Objective</h3>
              <p className="mt-5 text-base text-slate-400 sm:text-left lg:text-lg">
                The core objective of Sky Global Education is to provide students with the tools, resources, and support necessary to excel in their academic and professional endeavors. We are dedicated to guiding students through the complexities of applying for scholarships, securing admissions to prestigious institutions, and navigating their educational journey with ease. Our aim is to simplify the process and offer personalized assistance to ensure that every student finds the right educational opportunities and succeeds in their chosen field. By focusing on comprehensive support and expert guidance, we strive to make the pursuit of education a rewarding and achievable experience for all students.
              </p>
            </div>
          </div>
          <div className="flex flex-col mb-10 animated fadeIn sm:flex-row">
            <div className="flex items-center mb-16 sm:w-1/2 md:w-5/12 sm:order-last">
              <img className="rounded-lg shadow-xl" src={imgc} alt="Student Benefits" />
            </div>
            <div className="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pr-16">
              <h3 className="mt-2 text-xl font-semibold sm:text-left sm:text-2xl">Work for Your Benefit</h3>
              <p className="mt-5 text-base text-slate-400 sm:text-left lg:text-lg">
                At Sky Global Education, we are dedicated to maximizing the benefits for our students by offering a wide array of services tailored to meet their unique needs. Whether you are seeking admission to top-ranked universities, exploring affordable study programs, applying for scholarships, or looking for internships and career opportunities, we have you covered. Our comprehensive services include personalized counseling, extensive resources, and a commitment to helping you achieve the best possible outcomes. We work tirelessly to ensure that our students receive the highest level of support and benefit from our expertise and resources throughout their educational journey.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="py-16">
        <div className="flex flex-col px-8 mx-auto space-y-12 max-w-7xl xl:px-12">
          <div className="relative">
            <h2 className="w-full text-3xl font-bold text-center sm:text-4xl">Discover Benefits with Sky Global Education</h2>
            <p className="w-full py-8 mx-auto -mt-2 text-base text-center text-slate-400 font-medium sm:max-w-3xl">
              Explore the extensive benefits of partnering with Sky Global Education and learn how our dedicated services can help you achieve your educational and career goals.
            </p>
          </div>
          <div className="mb-10 animated fadeIn">
            <div className="mt-5">
              <h3 className="mt-8 text-xl font-semibold sm:text-left sm:text-2xl">Economical Study Options</h3>
              <p className="mt-5 mb-10 pb-10 border-b-2 text-base text-slate-400 sm:text-left lg:text-lg lg:text-justify">
                At Sky Global Education, we believe that high-quality education should be accessible to all students, regardless of their financial situation. We offer a variety of cost-effective study options, including scholarships, grants, and financial aid, to help students overcome financial obstacles and pursue their academic goals. Our commitment is to make higher education affordable and achievable, providing students with the opportunity to access top-tier programs and institutions without the burden of excessive costs. We understand the financial challenges students face and strive to provide solutions that make education more accessible and affordable.
              </p>
              <h3 className="mt-8 text-xl font-semibold sm:text-left sm:text-2xl">Comprehensive Programs and Resources</h3>
              <p className="mt-5 mb-10 pb-10 border-b-2 text-base text-slate-400 sm:text-left lg:text-lg lg:text-justify">
                We offer a comprehensive range of programs and resources designed to give students a competitive edge in their academic and professional pursuits. Our offerings include online courses, mentorship programs, and access to a vast library of educational materials. We provide support in areas such as test preparation, career counseling, and skill development, ensuring that students are well-prepared to succeed in their chosen fields. Whether you need assistance with SAT/IELTS/GRE/GMAT preparation or guidance in choosing the right career path, Sky Global Education has the resources and expertise to support you every step of the way.
              </p>
              <h3 className="mt-8 text-xl font-semibold sm:text-left sm:text-2xl">Excellent Job Opportunities</h3>
              <p className="mt-5 mb-10 pb-10 border-b-2 text-base text-slate-400 sm:text-left lg:text-lg lg:text-justify">
                Sky Global Education is dedicated to helping students secure excellent job opportunities by connecting them with industry leaders and providing essential career development resources. We organize job fairs, internships, online interviews, and career development workshops to help students explore various career paths and connect with potential employers. Our goal is to equip students with the skills and experiences needed to excel in the professional world and achieve their career aspirations. We provide the necessary support to enhance your resume, build your network, and secure desirable job opportunities during and after your study program.
              </p>
              <h3 className="mt-8 text-xl font-semibold sm:text-left sm:text-2xl">Best University Admissions</h3>
              <p className="mt-5 mb-10 pb-10 border-b-2 text-base text-slate-400 sm:text-left lg:text-lg lg:text-justify">
                At Sky Global Education, we are committed to helping students gain admission to the best universities around the world. Our expert team provides personalized assistance throughout the application process, including preparing college applications, writing impactful essays, and conducting mock interviews. We aim to enhance your chances of acceptance into top-tier institutions by offering tailored guidance and support. Whether you aspire to study medicine, engineering, or any other field, we assist with entrance exams, personal statements, and recommendation letters, ensuring that you present a strong application and achieve your academic goals.
              </p>
              <h3 className="mt-8 text-xl font-semibold sm:text-left sm:text-2xl">Guaranteed Outcomes</h3>
              <p className="mt-5 mb-10 pb-10 border-b-2 text-base text-slate-400 sm:text-left lg:text-lg lg:text-justify">
                Sky Global Education is dedicated to ensuring successful outcomes for our students. We offer specialized training programs designed to equip students with the skills and knowledge needed to secure high-paying jobs in their chosen fields. Our commitment is to help students achieve their career goals and enjoy a fulfilling and prosperous career. By joining Sky Global Education, you gain access to comprehensive support and resources that enhance your chances of success in the competitive job market.
              </p>
              <h3 className="mt-8 text-xl font-semibold sm:text-left sm:text-2xl">Maximum Benefits</h3>
              <p className="mt-5 mb-10 pb-10 text-base text-slate-400 sm:text-left lg:text-lg lg:text-justify">
                We are committed to providing students with the maximum benefits necessary for their educational and professional success. By partnering with Sky Global Education, students gain access to valuable resources, personalized counseling, and networking opportunities with industry professionals. Our goal is to ensure that every student receives the support they need to excel academically and professionally, maximizing their educational journey and career prospects.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AboutDescription
