import listing1 from "../assets/images/listing/1.jpg";
import listing2 from "../assets/images/listing/2.jpg";
import listing3 from "../assets/images/listing/3.jpg";
import listing4 from "../assets/images/listing/4.jpg";
import listing5 from "../assets/images/listing/5.jpg";
import listing6 from "../assets/images/listing/6.jpg";
import listing7 from "../assets/images/listing/7.jpg";
import listing8 from "../assets/images/listing/8.jpg";
import listing9 from "../assets/images/listing/9.jpg";
import listing10 from "../assets/images/listing/10.jpg";
import listing11 from "../assets/images/listing/11.jpg";


import client1 from "../assets/images/client/01.jpg";
import client2 from "../assets/images/client/02.jpg";
import client3 from "../assets/images/client/03.jpg";
import client4 from "../assets/images/client/04.jpg";
import client5 from "../assets/images/client/05.jpg";
import client6 from "../assets/images/client/06.jpg";
import client7 from "../assets/images/client/07.jpg";
import client16 from "../assets/images/client/16.jpg";


import blog1 from "../assets/images/blog/1.jpg";
import blog2 from "../assets/images/blog/2.jpg";
import blog3 from "../assets/images/blog/3.jpg";
import blog4 from "../assets/images/blog/4.jpg";
import blog5 from "../assets/images/blog/5.jpg";
import blog6 from "../assets/images/blog/6.jpg";
import blog7 from "../assets/images/blog/7.jpg";
import blog8 from "../assets/images/blog/8.jpg";
import blog9 from "../assets/images/blog/9.jpg";



import {
  FiShoppingCart,
  FiDribbble,
  FiLinkedin,
  FiFacebook,
  FiInstagram,
  FiTwitter,
  FiMail,

  FiYoutube,
  FiHelpCircle,
  FiBookmark,
  FiSettings,
  FiGithub,
  FiGitlab,
} from "../assets/icons/vander";



export const ClientData = [
  {
    image: client1,
    desc: '"Sky Global Education helped me secure a full scholarship to study in India. Their support throughout the application process was invaluable."',
    name: "Tawanda Moyo",
    possition: "Student at Lovely Professional University",
  },
  {
    image: client2,
    desc: '"Thanks to Sky Global Education, I am now pursuing my Master\'s degree in Australia. They guided me every step of the way."',
    name: "Chipo Ndlovu",
    possition: "Master's Student at University of Melbourne",
  },
  {
    image: client3,
    desc: '"The scholarship opportunities and guidance provided by Sky Global Education were a game changer for my academic career."',
    name: "Farai Mutasa",
    possition: "Undergraduate Student at University of Warsaw",
  },
  {
    image: client2,
    desc: '"I highly recommend Sky Global Education to any student looking to study abroad. Their expertise and resources made all the difference."',
    name: "Rumbi Chari",
    possition: "PhD Candidate at University of Edinburgh",
  },
  {
    image: client1,
    desc: '"Sky Global Education opened doors I never thought possible. I am grateful for their dedication and support."',
    name: "Kudzi Mahachi",
    possition: "Student at University of Oxford",
  },
  {
    image: client16,
    desc: '"With the help of Sky Global Education, I received a scholarship to study in Poland. Their team is truly amazing."',
    name: "Tatenda Nyoni",
    possition: "Student at University of Warsaw",
  },
];

export const blogData = [
  {
    id: 1,
    image: blog1,
    date: "13th Jul 2024",
    title: "How to Secure a Scholarship to Study Abroad",
    desc: "Discover the essential steps and tips for securing a scholarship to study abroad. Learn about the application process, required documents, and key deadlines.",
    tag: "Scholarships",
  },
  {
    id: 2,
    image: blog2,
    date: "29th Jun 2024",
    title: "Top Destinations for Zimbabwean Students",
    desc: "Explore the top study destinations for Zimbabwean students, including universities and programs that offer the best opportunities.",
    tag: "Destinations",
  },
  {
    id: 3,
    image: blog3,
    date: "29th May 2024",
    title: "Success Stories: Zimbabwean Students Abroad",
    desc: "Read inspiring success stories of Zimbabwean students who have secured scholarships and achieved their dreams of studying abroad.",
    tag: "Success Stories",
  },
  {
    id: 4,
    image: blog4,
    date: "13th Mar 2024",
    title: "Preparing for Your Study Abroad Journey",
    desc: "Get practical advice on how to prepare for your study abroad journey, from packing tips to cultural adjustments and more.",
    tag: "Preparation",
  },
  {
    id: 5,
    image: blog5,
    date: "5th Apr 2024",
    title: "Understanding Scholarship Requirements",
    desc: "Learn about the common requirements for scholarships and how to ensure you meet them to increase your chances of success.",
    tag: "Requirements",
  },
  {
    id: 6,
    image: blog6,
    date: "19th Jun 2024",
    title: "Financial Aid Options for International Students",
    desc: "Explore various financial aid options available to international students, including scholarships, grants, and loans.",
    tag: "Financial Aid",
  },
  {
    id: 7,
    image: blog7,
    date: "20th Jan 2024",
    title: "The Benefits of Studying Abroad",
    desc: "Understand the numerous benefits of studying abroad, from academic and career advantages to personal growth and cultural enrichment.",
    tag: "Benefits",
  },
  {
    id: 8,
    image: blog8,
    date: "31st Aug 2023",
    title: "Navigating Visa Applications for Studying Abroad",
    desc: "Get detailed guidance on the visa application process for studying abroad, including required documents and interview tips.",
    tag: "Visa",
  },
  {
    id: 9,
    image: blog9,
    date: "1st Sep 2023",
    title: "Choosing the Right Study Program",
    desc: "Tips and advice on how to choose the right study program and university to match your academic interests and career goals.",
    tag: "Study Programs",
  },
];

export const footerSocial = [
  {
    icon: FiShoppingCart,
    link: "https://1.envato.market/travosy-react",
  },
  {
    icon: FiDribbble,
    link: "https://dribbble.com/shreethemes",
  },
  {
    icon: FiLinkedin,
    link: "http://linkedin.com/company/shreethemes",
  },
  {
    icon: FiFacebook,
    link: "https://www.facebook.com/shreethemes",
  },
  {
    icon: FiInstagram,
    link: "https://www.instagram.com/shreethemes",
  },
  {
    icon: FiTwitter,
    link: "https://twitter.com/shreethemes",
  },
  {
    icon: FiMail,
    link: "mailto:support@shreethemes.in",
  },
];

export const footerCompany = [
  {
    name: "About us",
    link: "/aboutus",
  },
  {
    name: "Services",
    link: "/services",
  },
  {
    name: "Team",
    link: "/team",
  },
  
  {
    name: "Blog",
    link: "/blogs",
  },
];

export const placeImage = [
  listing1,
  listing2,
  listing3,
  listing4,
  listing5,
  listing6,
  listing7,
  listing8,
  listing9,
  listing10,
  listing11,
  listing2,
  listing3,
  listing4,
];
export const faqData = [
    {
      id: 1,
      title: "How do I apply for a scholarship?",
      desc: "To apply for a scholarship, visit our application portal and follow the step-by-step instructions. Make sure to review the eligibility criteria and gather all required documents before starting your application.",
    },
    {
      id: 2,
      title: "What are the eligibility criteria for scholarships?",
      desc: "Eligibility criteria vary by scholarship. Generally, they include academic performance, financial need, and specific program requirements. Check the details on our scholarship page for precise criteria.",
    },
    {
      id: 3,
      title: "When will I hear back about my application?",
      desc: "Scholarship review timelines vary. Typically, you will receive a response within a few weeks after the application deadline. Check your email and our portal for updates on your application status.",
    },
    {
      id: 4,
      title: "Can I apply for multiple scholarships?",
      desc: "Yes, you can apply for multiple scholarships. However, be sure to carefully read the guidelines for each scholarship to ensure you meet all requirements and deadlines.",
    },
    {
      id: 5,
      title: "What should I do if I encounter issues with my application?",
      desc: "If you experience any issues with your application, contact our support team immediately through the support request form on our website. We’re here to help you resolve any problems.",
    },
  ];
 
export const teamData = [
    {
      image: client4,
      name: "Warren Chishiri",
      position: "Scholarship Coordinator/Founder/CEO",
      socialLinks: {
        facebook: "https://www.facebook.com/warren.chishiri",
        instagram: "https://www.instagram.com/warren_tanyaradzwa_chishiri?igsh=MWF6cGZiNnJkYTkwag==",
        linkedin: "https://www.linkedin.com/in/warren-chishiri-807363152?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      },
    },
    {
      image: client5,
      name: "Tinotenda Rukanzakanza",
      position: "Education Consultant",
      socialLinks: {
        facebook: "https://www.facebook.com/tino.ruks",
        instagram: "https://www.instagram.com/antony_ruks?igsh=dzJ6ajVxd251MXVv",
        linkedin: "https://linkedin.com/in/tinotendarukanzakanza",
      },
    },
    {
      image: client6,
      name: "McInnocent Madzima",
      position: "Education Consultant",
      socialLinks: {
        facebook: "https://www.facebook.com/macexplored",
        instagram: "https://www.instagram.com/macmadzima?igsh=YmlnbmZsbmpxaHoz",
        linkedin: "https://linkedin.com/in/mcinnoceantmadzima",
      },
    },
    {
      image: client7,
      name: "Tendai Gumunyu",
      position: "IT Manager",
      socialLinks: {
        facebook: "https://www.facebook.com/tendai.gumunyu.1",
        instagram: "https://www.instagram.com/costa_developer?igsh=MWU4dmJodHZlZjcxeQ==",
        linkedin: "https://www.linkedin.com/in/tendai-gumunyu-759442207?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      },
    },
  ];
 
export const userSocialData = [
  {
    icon: FiTwitter,
    name: "Twitter",
    placeHolder: "Twitter Profile Name",
    desc: "Add your Twitter username (e.g. jesus).",
  },
  {
    icon: FiFacebook,
    name: "Facebook",
    placeHolder: "Facebook Profile Name",
    desc: "Add your Facebook username (e.g. jesus).",
  },
  {
    icon: FiInstagram,
    name: "Instagram",
    placeHolder: "Instagram Profile Name",
    desc: "Add your Instagram username (e.g. jesus).",
  },
  {
    icon: FiLinkedin,
    name: "Linkedin",
    placeHolder: "Linkedin Profile Name",
    desc: "Add your Linkedin username (e.g. jesus).",
  },
  {
    icon: FiYoutube,
    name: "Youtube",
    placeHolder: "Youtube url",
    desc: "Add your Youtube url.",
  },
];
export const helpAbout = [
    {
      icon: FiHelpCircle,
      title: "FAQs",
      desc: "Find answers to the most common questions about our scholarship programs and application processes.",
      link: "/helpcenter-faqs",
    },
    {
      icon: FiBookmark,
      title: "Guides / Support",
      desc: "Access detailed guides and support resources to help you with scholarship applications and requirements.",
      link: "/helpcenter-guides",
    },
    {
      icon: FiSettings,
      title: "Support Request",
      desc: "Submit a support request for personalized assistance with your scholarship applications or inquiries.",
      link: "/helpcenter-support",
    },
  ];
  

export const restrictions = [
    "Collection of Personal Data: We do not collect sensitive personal data without your consent.",
    "Usage of Data: We use your data solely for the purposes stated in our privacy policy.",
    "Data Sharing: We do not share your personal data with third parties without your explicit permission.",
    "Security Measures: We implement security measures to protect your personal data from unauthorized access.",
    "Data Retention: We retain your data only for as long as necessary to fulfill the purposes for which it was collected.",
    "Your Rights: You have the right to access, correct, or delete your personal data.",
  ];
  

export const blogSocial = [
  FiFacebook,
  FiInstagram,
  FiTwitter,
  FiLinkedin,
  FiGithub,
  FiYoutube,
  FiGitlab,
];
