import React, { useState } from 'react'
import Navbar from "../components/navbar";
import { Link } from "react-router-dom";

import Blogs from '../components/blogs'
import Client from '../components/client'
import About from '../components/about'
import Footer from '../components/footer';
import Switcher from '../components/switcher';

import { teamData,placeImage } from "../data/data";

import {FiFacebook, FiInstagram, FiLinkedin} from "../assets/icons/vander"

import TinySlider from "tiny-slider-react";
import 'tiny-slider/dist/tiny-slider.css';

import Lightbox from 'react-18-image-lightbox';
import "react-18-image-lightbox/style.css"
import { Gallery } from '../components/gallery';


export default function Portfolio(){
    let [isOpen, setisOpen] = useState(false);
    let [currentImageIndex, setCurrentImageIndex] = useState(0);

    let handleMovePrev = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + placeImage.length - 1) % placeImage.length);
    };

    let handleMoveNext = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % placeImage.length);
    };
    let currentImage = placeImage[currentImageIndex];

    let handleImageClick = (index) => {
        setCurrentImageIndex(index);
        setisOpen(true);
    };

    const settings = {  
        container: '.tiny-twelve-item',
        controls: true,
        mouseDrag: true,
        loop: true,
        rewind: true,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayTimeout: 3000,
        navPosition: "bottom",
        controlsText: ['<i class="mdi mdi-chevron-left "></i>', '<i class="mdi mdi-chevron-right"></i>'],
        nav: false,
        speed: 400,
        gutter: 0,
        responsive: {
            1025: {
                items: 12
            },

            992: {
                items: 8
            },

            767: {
                items: 6
            },

            575: {
                items: 5
            },

            420: {
                items: 3
            },

            320: {
                items: 2
            },
        },
      };
    return(
        <>
        <Navbar navclass="defaultscroll is-sticky" navlight={true} manuclass="justify-end nav-light"/>
        <section className="relative table w-full items-center py-36 bg-[url('../../assets/images/bg/cta.jpg')] bg-top bg-no-repeat bg-cover">
            <div className="absolute inset-0 bg-gradient-to-b from-slate-900/60 via-slate-900/80 to-slate-900"></div>
            <div className="container relative">
                <div className="grid grid-cols-1 pb-8 text-center mt-10">
                    <h3 className="text-4xl leading-normal tracking-wider font-semibold text-white">SKYGLOBAL EDUCATION</h3>
                </div>
            </div>
            
            <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                <ul className="tracking-[0.5px] mb-0 inline-block">
                    <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">SKYGLOBAL</Link></li>
                    <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><i className="mdi mdi-chevron-right"></i></li>
                    <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white" aria-current="page">Explore Our Success Stories</li>
                </ul>
            </div>
        </section>
       
        <section className="relative md:pb-24 pb-16">
            <div className='text-center mt-5'>
            <h1 className='mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold'>Promoting Moral Values and Ethics: A Project by Sky Global Students</h1>
            <p className='text-slate-400 md:mx-10 sm:mx-auto'>Sky Global students consistently demonstrate commendable moral values and ethics in their academic and personal lives. The diverse and inclusive environment at Sky Global encourages students to embrace the principles of respect, empathy, and integrity, fostering a sense of global citizenship

This commitment to strong moral character extends to their interactions with peers and professors, promoting a culture of inclusivity and collaboration. Students at Sky Global are encouraged to engage in community service and volunteer activities, reinforcing their commitment to making a positive impact on society.

These shared values of empathy and social responsibility not only enrich the academic experience but also prepare Sky Global students to be ethical leaders and global citizens in an increasingly interconnected world.</p>
            </div>
            <div className='md:mx-40 md:my-20'>
            <Gallery />
            </div>
           

            <div className="container relative md:mt-24 mt-16">
                <div className="grid grid-cols-1 pb-6 text-center">
                    <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Our Team</h3>

                    <p className="text-slate-400 max-w-xl mx-auto">At Sky Global Education, our dedicated team is the heart of our mission to connect students with life-changing educational scholarships. Each member brings a unique set of skills and expertise, working collaboratively to provide unparalleled support and guidance throughout the scholarship journey. Get to know the passionate individuals who make our work possible and are committed to helping you achieve your educational dreams</p>
                </div>

                <div className="grid md:grid-cols-12 grid-cols-1 mt-8 gap-[30px]">
  {teamData.map((item, index) => (
    <div className="lg:col-span-3 md:col-span-6" key={index}>
      <div className="group text-center">
        <div className="relative inline-block mx-auto h-52 w-52 rounded-full overflow-hidden">
          <img src={item.image} alt={item.name} className="object-cover h-full w-full" />
          <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black h-52 w-52 rounded-full opacity-0 group-hover:opacity-100 duration-500"></div>

          <ul className="list-none absolute start-0 end-0 -bottom-20 group-hover:bottom-5 duration-500 space-x-1">
            <li className="inline">
              <a
                href={item.socialLinks.facebook}
                target="_blank"
                rel="noopener noreferrer"
                className="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center rounded-md border border-red-500 bg-red-500 text-white"
              >
                <FiFacebook className="size-4" />
              </a>
            </li>
            <li className="inline">
              <a
                href={item.socialLinks.instagram}
                target="_blank"
                rel="noopener noreferrer"
                className="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center rounded-md border border-red-500 bg-red-500 text-white"
              >
                <FiInstagram className="size-4" />
              </a>
            </li>
            <li className="inline">
              <a
                href={item.socialLinks.linkedin}
                target="_blank"
                rel="noopener noreferrer"
                className="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center rounded-md border border-red-500 bg-red-500 text-white"
              >
                <FiLinkedin className="size-4" />
              </a>
            </li>
          </ul>
        </div>

        <div className="content mt-3">
          <Link to="" className="text-lg font-semibold hover:text-red-500 duration-500">
            {item.name}
          </Link>
          <p className="text-slate-400">{item.position}</p>
        </div>
      </div>
    </div>
  ))}
</div>
            </div>

            <Client/>

            <Blogs/>
        </section>
        <Footer/>
        <Switcher/>
        </>
    )
}