import React from 'react';

// MUI Icons
import SchoolIcon from '@mui/icons-material/School';
import VerifiedIcon from '@mui/icons-material/Verified';
import BadgeIcon from '@mui/icons-material/Badge';
import StarIcon from '@mui/icons-material/Star';

const Stats = () => {
    return (
        <div className="mx-auto md:max-w-xl lg:max-w-screen-xl grid gap-8 grid-cols-2 lg:grid-cols-4 mt-12">
            <div className="text-center">
                <div className="flex items-center justify-center w-16 h-16 mx-auto mb-3 rounded-full bg-cyan-50 shadow-md">
                    <SchoolIcon className="w-8 h-8 sm:w-10 sm:h-10 text-cyan-800" />
                </div>
                <h6 className="text-base sm:text-2xl lg:text-4xl font-bold text-cyan-800">25000+</h6>
                <p className="text-sm mb-2 font-semibold text-slate-400">Student Counselled</p>
            </div>
            <div className="text-center">
                <div className="flex items-center justify-center w-16 h-16 mx-auto mb-3 rounded-full bg-indigo-50 shadow-md">
                    <BadgeIcon className="w-8 h-8 sm:w-10 sm:h-10 text-indigo-400" />
                </div>
                <h6 className="text-base sm:text-2xl lg:text-4xl font-bold text-indigo-400">3000+</h6>
                <p className="text-sm mb-2 font-semibold text-slate-400">Placed Successfully</p>
            </div>
            <div className="text-center">
                <div className="flex items-center justify-center w-16 h-16 mx-auto mb-3 rounded-full bg-green-50 shadow-md">
                    <VerifiedIcon className="w-8 h-8 sm:w-10 sm:h-10 text-green-700" />
                </div>
                <h6 className="text-base sm:text-2xl lg:text-4xl font-bold text-green-700">99%</h6>
                <p className="text-sm mb-2 font-semibold text-slate-400">Visa Success Rate</p>
            </div>
            <div className="text-center">
                <div className="flex items-center justify-center w-16 h-16 mx-auto mb-3 rounded-full bg-orange-50 shadow-md">
                    <StarIcon className="w-8 h-8 sm:w-10 sm:h-10 text-orange-900" />
                </div>
                <h6 className="text-base sm:text-2xl lg:text-4xl font-bold text-orange-900">5+</h6>
                <p className="text-sm mb-2 font-semibold text-slate-400">Years of Experience</p>
            </div>
        </div>
    );
}

export default Stats;
