import React from "react";
import imga from "../assets/images/gallery/a.jpg"
import imgb from "../assets/images/gallery/b.jpg"
import imgc from "../assets/images/gallery/c.jpg"
import imgd from "../assets/images/gallery/d.jpg"
import imge from "../assets/images/gallery/e.jpg"
import imgf from "../assets/images/gallery/f.jpg"
import imgg from "../assets/images/gallery/g.jpg"
import imgh from "../assets/images/gallery/h.jpg"
import imgi from "../assets/images/gallery/i.jpg"
import imgj from "../assets/images/gallery/j.jpg"
import imgk from "../assets/images/gallery/k.jpg"
import imgl from "../assets/images/gallery/l.jpg"
import imgm from "../assets/images/gallery/m.jpg"

export function Gallery() {
  return (
    <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-4">
      <div>
        <img className="w-full h-full object-cover rounded-lg" src={imgh} alt="Gallery Image 1" />
      </div>
      <div>
        <img className="w-full h-full object-cover rounded-lg" src={imgc} alt="Gallery Image 2" />
      </div>
      <div>
        <img className="w-full h-full object-cover rounded-lg" src={imgd} alt="Gallery Image 3" />
      </div>
      <div>
        <img className="w-full h-full object-cover rounded-lg" src={imgb} alt="Gallery Image 4" />
      </div>
      <div>
        <img className="w-full h-full object-cover rounded-lg" src={imgf} alt="Gallery Image 5" />
      </div>
      <div>
        <img className="w-full h-full object-cover rounded-lg" src={imgg} alt="Gallery Image 6" />
      </div>
      <div>
        <img className="w-full h-full object-cover rounded-lg" src={imga} alt="Gallery Image 7" />
      </div>
      <div>
        <img className="w-full h-full object-cover rounded-lg" src={imgi} alt="Gallery Image 8" />
      </div>
      <div>
        <img className="w-full h-full object-cover rounded-lg" src={imgj} alt="Gallery Image 9" />
      </div>
      <div>
        <img className="w-full h-full object-cover rounded-lg" src={imgk} alt="Gallery Image 10" />
      </div>
      <div>
        <img className="w-full h-full object-cover rounded-lg" src={imgl} alt="Gallery Image 11" />
      </div>
      <div>
        <img className="w-full h-full object-cover rounded-lg" src={imgm} alt="Gallery Image 12" />
      </div>
    </div>
  );
}
