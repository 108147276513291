import React from "react";
import bg2 from "../assets/images/bg/cta1.jpg";

const Card = () => {
  return (
    <div className="relative h-screen w-screen top-0 left-0 w-full h-full bg-cover bg-center bg-no-repeat" style={{backgroundImage: `url(${bg2})`, backgroundColor: "rgba(0, 0, 0, 0.9)" }}>
      {/* Background */}

      {/* Content */}
      <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center z-10">
        <h1 className="text-[#ffffff] text-3xl md:text-4xl lg:text-5xl font-bold mb-6 md:mb-8 text-center px-4">Unlock Your Educational Potential</h1>
        <h2 className="text-[#ffffff] text-lg md:text-xl lg:text-2xl font-semibold mb-4 text-center px-4">Empowering Futures Through Education</h2>
        <p className="text-[#ffffff] text-base md:text-lg lg:text-xl max-w-md md:max-w-lg lg:max-w-xl text-center px-4">At Sky Global Education, we provide resources and opportunities to help you achieve your educational goals. Whether you're looking for scholarships, study abroad programs, or career development resources, we are here to support you. Join us in making your educational dreams a reality and build a brighter future.</p>
      </div>
    </div>
  );
};

export default Card;
